import React, { useContext, useState } from "react";
import { FilePdfOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import useGetDocTypeList from "../hooks/useGetDocTypeList";
import { AppContext } from "../context/appContext";
import ViewerDoctype from "../components/viewerDoctype";
import getPrintFormatPDF from "../helpers/getPrintFormatPDF";
import getSalesInvoiceItem from "../helpers/getSalesInvoiceItem";
import ModalDetailDoc from "../components/modalDetailDoc";
import languageResources from "../enums/language";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const { userInfo, languageSelected, setOrderDetail } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [modalData] = useState()
  const [docType] = useState("Sales Order");
  const [fields] = useState([
    "name",
    "transaction_date",
    "customer",
    "customer_name",
    "billing_status",
    "grand_total",
  ]);
  const [filter] = useState([
    ["customer", "=", userInfo.cliente_id],
    ["status", "!=", "cancelled"],
  ]);
  const [orderBy] = useState("transaction_date DESC");
  const { data, isLoading } = useGetDocTypeList(
    docType,
    fields,
    filter,
    orderBy
  );

  const navigate = useNavigate();

  const columns = [
    {
      title: "Date",
      dataIndex: "transaction_date",
      align: "center",
      key: "transaction_date",
    },
    {
      title: "Sales Order",
      dataIndex: "name",
      align: "center",
      key: "name",
    },
    {
      title: "Billing Status",
      dataIndex: "billing_status",
      align: "center",
      key: "billing_status",
    },
    {
      title: "Total",
      dataIndex: "grand_total",
      align: "center",
      key: "grand_total",
      render: (_, { grand_total }) => {
        return `${formatToCurrency(grand_total)}`;
      }
    },
    {
      title: languageResources[languageSelected].page.salesOrders.actions,
      dataIndex: "",
      align: "center",
      key: "x",
      render: (record) => {
        return (
          <>
            <Tooltip title="Descargar Orden">
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<FilePdfOutlined />}
                onClick={() => downloadDocument(record)}
              />
            </Tooltip>
            <Tooltip title="Show details">
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => gotoDetail(record)}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const downloadDocument = (record) => {
    const resPDF = getPrintFormatPDF(
      "Sales Order",
      record.name,
      "SO Custom JAS 01"
    );
    console.log(resPDF);
  };


  const gotoDetail = async (record) => {
    const resDetail = await getSalesInvoiceItem(record.name, "Sales Order");
    setOrderDetail(resDetail)
    navigate(`/detailOrder/${record.name}`, { state: { orderDetail: resDetail } });
  }

  const onClose = () => {
    setShowModal(false);
  };

  const formatToCurrency = (number) => {
    if (isNaN(number)) {
      throw new Error("El valor proporcionado no es un número válido.");
    }
  
    return `$${parseFloat(number).toFixed(2)}`;
  };

  return (
    <>
      <ViewerDoctype
        columns={columns}
        data={data}
        isLoading={isLoading}
        title={languageResources[languageSelected].page.salesOrders.title}
      />
      <ModalDetailDoc visible={showModal} onClose={onClose} data={modalData}/>
    </>
  );
};

export default Orders;
