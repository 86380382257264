import React from "react";


const Home = () => {
  return (
    <p>Home</p>
  );
};

export default Home;
