import React, { useState } from "react";
import { Table, Spin, Typography } from "antd";

const { Title } = Typography;

const paginationLocale = {
  items_per_page: "/ página",
  jump_to: "Saltar a",
  jump_to_confirm: "confirmar",
  page: "",
  prev_page: "Página anterior",
  next_page: "Página siguiente",
  prev_5: "5 páginas anteriores",
  next_5: "5 páginas siguientes",
  prev_3: "3 páginas anteriores",
  next_3: "3 páginas siguientes",
};

const ViewerDoctype = ({ columns, data, isLoading, title }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  return (
    <>
      <Title level={2} style={{ textAlign: "center" }}>
        {title}
      </Title>
      <Spin spinning={isLoading}>
        <Table
          columns={columns}
          dataSource={data}
          bordered
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: data?.length,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ["5", "10", "20", "50"],
            locale: paginationLocale,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            },
          }}
        />
      </Spin>
    </>
  );
};

export default ViewerDoctype;
