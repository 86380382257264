import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  Card,
  Col,
  Form,
  Row,
  Input,
  DatePicker,
  Table,
  Button,
  Spin,
  message,
  Select,
  Typography,
  Tooltip,
} from "antd";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/appContext";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import useRunReport from "../hooks/useRunReport";
import QuantityInput from "./quantityInput";
import createDocType from "../helpers/postCreateDocType";

const { Title } = Typography;

const ViewSalesOrder = ({ orderDetail, title }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { userInfo } = useContext(AppContext);
  const isNew = useMemo(() => !orderDetail, [orderDetail]);
  const reportName = "Detail Price List";
  const filters = useMemo(
    () => ({ priceList: userInfo.price_list_por_defecto }),
    [userInfo]
  );

  const [dataSource, setDataSource] = useState([]);
  // const [options, setOptions] = useState([]);
  const [qtyToAdd, setQtyToAdd] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);

  const { data: itemList, isLoading } = useRunReport(reportName, filters);

  const options = Array.prototype.map.call(
    !isLoading ? itemList.result : [],
    (agr) => {
      return (
        <Select.Option key={agr.item_code} value={agr.item_code}>
          {agr.item_name}
        </Select.Option>
      );
    }
  );

  useEffect(() => {
    if (orderDetail) {
      form.setFieldsValue({
        salesOrder: orderDetail.name,
        customer: orderDetail.customer_name,
        date: dayjs(orderDetail.transaction_date),
        address: formatStringForTextarea(orderDetail.address_display),
        deliveryDate: dayjs(orderDetail.delivery_date),
        totalTaxesAndCharges: formatToCurrency(
          orderDetail.total_taxes_and_charges
        ),
        grandTotal: formatToCurrency(orderDetail.grand_total),
        totalQty: orderDetail.total_qty,
        totalItems: formatToCurrency(orderDetail.net_total),
      });
      setDataSource(orderDetail.items);
    } else {
      form.setFieldValue({ qty: 0, totalItems: 0 });
    }
  }, [form, orderDetail]);

  const columns = useMemo(
    () => [
      {
        title: "Code",
        dataIndex: "item_code",
        align: "center",
        key: "item_code",
      },
      {
        title: "Description",
        dataIndex: "item_name",
        align: "center",
        key: "item_name",
      },
      {
        title: "Group",
        dataIndex: "item_group",
        align: "center",
        key: "item_group",
      },
      { title: "Qty", dataIndex: "qty", align: "center", key: "qty" },
      {
        title: "Price",
        dataIndex: "rate",
        align: "center",
        key: "rate",
        render: (_, { rate }) => formatToCurrency(rate),
      },
      {
        title: "Subtotal",
        dataIndex: "amount",
        align: "center",
        key: "amount",
      },
      {
        title: "Options",
        dataIndex: "",
        align: "center",
        key: "x",
        render: (record) => {
          return (
            <>
              <Tooltip title="Erase">
                <Button
                  size="large"
                  type="primary"
                  shape="circle"
                  icon={<CloseOutlined />}
                  onClick={() => eraseRecord(record)}
                />
              </Tooltip>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const formatStringForTextarea = (inputString = "") =>
    inputString.replace(/<br\s*\/?>/gi, "\n");

  const formatToCurrency = (number) => `$${(+number || 0).toFixed(2)}`;

  const eraseRecord = (record) => {
    const newArray = dataSource.filter(
      (obj) => obj.item_code !== record.item_code
    );
    const { qty: currentQty, totalItems: currentTotal } = form.getFieldsValue();
    const newQty = currentQty - record.qty;
    const newtotalItems = currentTotal - record.qty * record.rate;
    form.setFieldsValue({
      qty: newQty,
      totalItems: newtotalItems,
    });
    setDataSource(newArray);
  };

  const onSelect = (selectedString) => {
    const selectedCode = selectedString.split(" - ")[0].trim();
    const matchedItem = itemList?.result?.find(
      (item) => item.item_code === selectedCode
    );
    setSelectedItem(matchedItem || null);
  };

  const addItem = () => {
    const { item_code, item_name, item_group, price_list_rate } = selectedItem;
    const newArray = dataSource.filter((obj) => obj.item_code === item_code);
    if (newArray.length === 0) {
      const { qty: currentQty, totalItems: currentTotal } =
        form.getFieldsValue();
      const formValues = form.getFieldsValue();
      console.log({
        currentQty,
        qtyToAdd,
        price_list_rate,
        currentTotal,
        formValues,
      });
      const newQty = currentQty + qtyToAdd;
      const newTotal = currentTotal + qtyToAdd * price_list_rate;
      form.setFieldsValue({ qty: newQty, totalItems: newTotal });
      setDataSource([
        ...dataSource,
        {
          item_code,
          item_name,
          item_group,
          qty: qtyToAdd,
          rate: price_list_rate,
          amount: formatToCurrency(qtyToAdd * price_list_rate),
        },
      ]);
      setQtyToAdd(1);
    } else {
      message.error(`Item ${item_name} is already on the list`);
    }
  };

  const disabledDate = (current) => {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return current && current.toDate() <= today;
  };

  const onFinish = async (values) => {
    const { address, customer, date, deliveryDate } = values;
    console.log({ address, customer, date, deliveryDate, userInfo });

    const bodyRequest = {};

    const response = await createDocType(bodyRequest);
    console.log({ response });
  };

  const disabledDeliveryDate = (current) => {
    const dateInitial = form.getFieldValue("date");
    const today = new Date(dateInitial);
    today.setHours(23, 59, 59, 999);
    return current && current.toDate() <= today;
  };

  return (
    <Spin spinning={isLoading}>
      <Row justify="space-between">
        <Col>
          {!isNew && (
            <Tooltip title="Back">
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate(-1)}
              />
            </Tooltip>
          )}
        </Col>

        <Col>
          <Title level={2} style={{ textAlign: "center" }}>
            {title}
          </Title>
        </Col>
        <Col></Col>
      </Row>

      <Form
        name="formSalesOrder"
        onFinish={onFinish}
        onFinishFailed={() =>
          message.error("Diligencie la información requerida")
        }
        layout="vertical"
        size="large"
        form={form}
        disabled={!isNew}
      >
        <Card style={{ marginBottom: "10px" }}>
          <p>
            <strong>General Info</strong>
          </p>
          <Row>
            {!isNew && (
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label="Sales Order" name="salesOrder">
                  <Input readOnly style={{ width: "90%" }} />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Customer" name="customer">
                <Input
                  readOnly
                  style={{ width: "90%" }}
                  defaultValue={userInfo.nombre_cliente}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Date" name="date">
                <DatePicker
                  disabledDate={disabledDate}
                  style={{ width: "90%" }}
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese la fecha para cumplimiento",
                    },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Delivery Date" name="deliveryDate">
                <DatePicker
                  style={{ width: "90%" }}
                  disabledDate={disabledDeliveryDate}
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese la fecha para cumplimiento",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Form.Item label="Address" name="address">
                <TextArea style={{ width: "90%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card style={{ marginBottom: "10px" }}>
          <p>
            <strong>Items</strong>
          </p>
          {isNew && (
            <>
              <Row justify="space-between">
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    label="Search Item"
                    name="searchItem"
                    style={{ width: "90%" }}
                  >
                    <Select
                      onSelect={onSelect}
                      placeholder="Search an Item"
                      allowClear
                      value={selectedItem}
                    >
                      {options}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <Form.Item label=" " style={{ width: "90%" }}>
                    <QuantityInput value={qtyToAdd} setValue={setQtyToAdd} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <Form.Item label=" " style={{ width: "90%" }}>
                    <Button disabled={!selectedItem} onClick={addItem}>
                      Add Item
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Row justify="center">
            <Col xs={24} sm={24} lg={24}>
              <Table
                style={{ width: "96%", marginBottom: "20px" }}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
              />
            </Col>
          </Row>
          <Row justify="space-between">
            <Col xs={24} sm={24} lg={12}>
              <Form.Item name="qty" label=" " style={{ width: "90%" }}>
                <Input
                  defaultValue={orderDetail?.total_qty}
                  style={{ width: "70%" }}
                  readOnly
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item name="totalItems" label=" " style={{ width: "90%" }}>
                <Input
                  defaultValue={formatToCurrency(orderDetail?.base_total)}
                  style={{ width: "70%" }}
                  readOnly
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        {!isNew && (
          <>
            <Card style={{ marginBottom: "10px" }}>
              <p>
                <strong>Taxes</strong>
              </p>
              <Row justify="end">
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item
                    label="Total Taxes and Charges"
                    name="totalTaxesAndCharges"
                  >
                    <Input style={{ width: "90%" }} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card style={{ marginBottom: "10px" }}>
              <p>
                <strong>Total</strong>
              </p>
              <Row justify="end">
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Grand Total" name="grandTotal">
                    <Input style={{ width: "90%" }} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </>
        )}
        {isNew && (
          <Row align="center">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                size="large"
              >
                Save Sales Order
              </Button>
            </Form.Item>
          </Row>
        )}
      </Form>
    </Spin>
  );
};

export default ViewSalesOrder;
