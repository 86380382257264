import BACK_API from "../api/erpNextAPI";
import { message } from "antd";


const createDocType = async (docTypeInfo) => {
  try {
    // Validar que `docTypeInfo` sea un objeto y tenga al menos el `doctype`
    // if (!docTypeInfo || typeof docTypeInfo !== "object" || !docTypeInfo.doctype) {
    //   throw new Error("El objeto docTypeInfo es inválido o falta el campo 'doctype'.");
    // }

    // Realizar la solicitud
    const result = await BACK_API().post(
      `proxy/api/method/frappe.desk.form.save.savedocs`, // Usar un endpoint más adecuado para crear documentos
      docTypeInfo // Pasar la información completa del DocType
    );

    // Validar respuesta del servidor
    if (result?.data?.message) {
      message.success("El documento fue creado exitosamente.");
      return result.data.message; // Retorna el objeto del DocType creado
    } else {
      throw new Error("La respuesta del servidor no es válida.");
    }
  } catch (error) {
    // Manejo de errores
    console.error("Error al crear el DocType:", error.message || error);
    message.error(`No fue posible guardar el documento: ${error.message}`);
    return false; // Indicar que hubo un fallo
  }
};

export default createDocType;

